import React, { useState } from "react";
import "../public.css";

const Home = () => {
  return (
    <div className="homepage">
      <div className="container">
        <div className="row mt-5">
          <div className="col">
            <h2 className="h2 hppar fs-1">
              Ubicopia landing page for email / password confirmations.
            </h2>
            <p className="hppar fs-5">
              Thank you for using the online ordertool. Dank u om onze tool te
              gebruiken. Merci d'avoir utilisé l'outil Ubicopia.
            </p>
            <p className="hppar fs-5">
              Please close this screen. U mag gerust dit venster afsluiten.
              Merci de fermer ce logiciel.
            </p>
            <p className="hppar fs-5">
              Or continue to the main page. Of doorgaan naar de hoofdpagina.
            </p>
          </div>
        </div>
        <div className="row">
          <a
            className="btn btn-success bt-lg fs-1"
            target="_blank"
            href="https://ubicopia.be"
          >
            <button className="btn btn-success bt-lg fs-1">
              Naar Ubicopia homepage
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
