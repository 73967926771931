import React, { Fragment } from "react";

//Routing
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Context
import { AuthProvider } from "./Store/auth-context";

import ReturnSuccess from "./Components/ReturnSuccess";
import ReturnAbort from "./Components/ReturnAbort";
import ReturnFail from "./Components/ReturnFail";

//Pages
import Home from "./Pages/Home";
import ResetPassword from "./Pages/ResetPassword";
import Activate from "./Pages/Activate";
import ResetPasswordConfirm from "./Pages/ResetPasswordConfirm";

// Styles
// import { GlobalStyle } from "./GlobalStyle";

const App = () => (
  <Router>
    <AuthProvider>
      <div className="">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/returnsuccess/" element={<ReturnSuccess />} />
          <Route path="/returnabort/" element={<ReturnAbort />} />
          <Route path="/returnfail/" element={<ReturnFail />} />
          <Route path="/home/" element={<Home />} />
          <Route path="/reset-password/" element={<ResetPassword />} />
          <Route path="/activate/:uid/:token/" element={<Activate />} />
          <Route
            path="/password/reset/confirm/:uid/:token/"
            element={<ResetPasswordConfirm />}
          />
        </Routes>
      </div>
    </AuthProvider>
  </Router>
);

export default App;
