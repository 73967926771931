import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../Store/auth-context";

const Activate = () => {
  const { uid, token } = useParams();
  const authObject = useContext(AuthContext);

  useEffect(() => {
    console.log("start verify process");
    authObject.setactivating(true);
    authObject.verifyaccount(uid, token);
    authObject.setactivating(false);
    console.log("verified");
    setTimeout(() => {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }, 5000);
  }, []);

  // useEffect(() => {
  //   console.log("verified");
  //   setTimeout(() => {
  //     window.opener = null;
  //     window.open("", "_self");
  //     window.close();
  //   }, 5000);
  // }, [authObject.verified]);

  return (
    <div className="actpage mx-auto mt-5">
      <div className="h1 text-center">Activatie van je account</div>
      <div className="mx-auto text-center mt-5">
        <div>Je emailadres is geverifieerd, waarvoor dank</div>
        <div className="mt-5 bg-success w-75 mx-auto fs-2 p-3">
          <p>Je account is geactiveerd.</p>
          <p>Je kan inloggen op de website van de handelaar</p>
          <p>Dit scherm sluit automatisch binnen enkele ogenblikken.</p>
        </div>
        <div className="row mt-5">
          <a className="" target="_blank" href="https://ubicopia.be">
            <button className="bg-transparent text-white fst-italic">
              Powered by Ubicopia®
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Activate;
