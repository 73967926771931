import { createContext, useState, useEffect } from "react";

import jwt_decode from "jwt-decode";

import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
  user: null,
  setuser: () => {},

  organisation: null,
  setorganisation: () => {},

  addresslist: null,
  getaddresslist: () => {},

  accesstoken: null,
  loginuser: () => {},
  logoutuser: () => {},
  signup: () => {},
  verifyaccount: () => {},
  activating: [],
  setactivating: () => {},
  getuserdetails: () => {},
  updateuserdetails: () => {},
  verifyaccount: () => {},
  verified: [],
  setverified: () => {},

  resetpassword: () => {},
  resetpasswordconfirm: () => {},
});

export function AuthProvider(props) {
  let [accessToken, setAccessToken] = useState(() =>
    localStorage.getItem("access") ? localStorage.getItem("access") : null
  );
  let [user, setUser] = useState();
  let [organisation, setOrganisation] = useState();
  let [addressList, setAddressList] = useState();

  let [activating, setActivating] = useState();
  let [verified, setVerified] = useState(false);

  const navigate = useNavigate();

  // checked
  const signUp = async (
    email,
    phone,
    title,
    firstname,
    name,
    password,
    re_password
  ) => {
    var url = process.env.REACT_APP_API_URL + "capi/users/register/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        phone,
        title,
        firstname,
        name,
        password,
        re_password,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("response received");
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  // checked
  const verifyAccount = async (uid, token) => {
    var url = `${process.env.REACT_APP_API_URL}capi/users/verify/${uid}/${token}/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      // .then((resp) => resp.json())
      .then((resp) => {
        if (resp.ok) {
          setVerified(true);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const resetPassword = async (email) => {
    var url = process.env.REACT_APP_API_URL + "capi/users/password-reset/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("response received:");
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  //checked
  const resetPasswordConfirm = async (
    uid,
    token,
    new_password,
    re_new_password
  ) => {
    var url =
      process.env.REACT_APP_API_URL + "capi/users/password-reset-confirm/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid, token, new_password, re_new_password }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("response received");
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  let getAddressList = async (userid) => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "capi/address/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          userid: userid,
        }),
      }
    );
    let data = await response.json();
    setAddressList(data);
  };

  let context = {
    user: user,
    setuser: setUser,

    addresslist: addressList,
    getaddresslist: getAddressList,

    organisation: organisation,
    setorganisation: setOrganisation,

    accesstoken: accessToken,

    verifyaccount: verifyAccount,
    activating: activating,
    setactivating: setActivating,
    verified: verified,
    setverified: setVerified,
    resetpassword: resetPassword,
    resetpasswordconfirm: resetPasswordConfirm,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
