import React, { useState, useEffect, useContext } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import AuthContext from "../Store/auth-context";

const ResetPasswordConfirm = () => {
  const authObject = useContext(AuthContext);

  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });
  const { new_password, re_new_password } = formData;
  const { uid, token } = useParams();
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    if (requestSent === true) {
      console.log("timer toggled");
      setTimeout(() => {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }, 5000);
    }
  }, [requestSent]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    authObject.resetpasswordconfirm(uid, token, new_password, re_new_password);
    setRequestSent(true);
  };

  const pwToggler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="resetpwpage w-75 mx-auto mt-5 mb-5">
      {requestSent === false && (
        <>
          <div className="h1 text-center">Reset Paswoord</div>
          <label className="mt-3">Gelieve je paswoord 2x in te vullen</label>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group mt-5">
              <label>
                Paswoord (min 8 characters, hoofd-, kleine letter, cijfer en
                speciaal character (bvb -/_\)
              </label>
              <div className="form-control d-flex justify-content-between">
                <input
                  className="bg-transparent border-0 w-75"
                  type={passwordType}
                  placeholder="Nieuw paswoord"
                  name="new_password"
                  value={new_password}
                  onInput={(e) => onChange(e)}
                  pattern="(?=^.{8,255}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                  required
                />
                <button
                  type="button"
                  className="bg-transparent border-0"
                  onClick={() => pwToggler()}
                >
                  {passwordType === "password" ? (
                    <i className="bi bi-eye fs-5"></i>
                  ) : (
                    <i className="bi bi-eye-slash fs-5"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="form-group mt-3">
              <label>Herhaal je paswoord</label>
              <div className="form-control d-flex justify-content-between">
                <input
                  className="bg-transparent border-0 w-75"
                  type={passwordType}
                  placeholder="Herhaal je paswoord"
                  name="re_new_password"
                  value={re_new_password}
                  onChange={(e) => onChange(e)}
                  pattern={new_password}
                  required
                />
                <button
                  type="button"
                  className="bg-transparent border-0"
                  onClick={() => pwToggler()}
                >
                  {passwordType === "password" ? (
                    <i className="bi bi-eye fs-5"></i>
                  ) : (
                    <i className="bi bi-eye-slash fs-5"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="form-group mt-5">
              <button className="btn btn-primary" type="submit">
                Bevestig reset
              </button>
            </div>
          </form>
        </>
      )}
      {requestSent === true && (
        <>
          <div className="h1 text-center">Reset Paswoord</div>
          <div className="mt-5 bg-success text-center py-2 px-3 fs-3 fw-bold">
            <p className="mt-3">Paswoord reset is geslaagd.</p>
            <div className="w-50 mx-auto">
              <hr></hr>
            </div>
            <p className="mt-3">Password reset sucessfull.</p>
            <div className="w-50 mx-auto">
              <hr></hr>
            </div>
            <p className="mt-3">Réinitialisation du mot de passe réussie.</p>
          </div>
          <div className="text-center mt-2">
            Pages closes automatically / Pagina sluit automatisch / Page ce
            ferme automatiquement
          </div>
          <div className="row mt-5">
            <a
              className="mx-auto d-flex justify-content-center"
              target="_blank"
              href="https://ubicopia.be"
            >
              <button className="bg-transparent text-white fst-italic">
                Powered by Ubicopia®
              </button>
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default ResetPasswordConfirm; // connect to wrap Reset Password Confirm page, as no mapStateToProps are used, and reset_password_confirm function is used
